@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

/*MEDIA SCREEN VARIABLES*/

/*media screen*/
$screen-desktopWide: 1920px;
$screen-desktop: 1440px;
$screen-tablet: 1080px;
$screen-mobileWide: 768px;
$screen-mobileMedium: 640px;
$screen-mobile: 500px;
$screen-mobileSmall: 400px;

/*END MEDIA SCREEN VARIABLES*/


/*MIXIN*/

@mixin respond($media) {
    @if $media==desktopWide {
        @media only screen and (max-width: $screen-desktopWide) {
            @content;
        }
    }
    @if $media==desktop {
        @media only screen and (max-width: $screen-desktop) {
            @content;
        }
    }
    @if $media==tablet {
        @media only screen and (max-width: $screen-tablet) {
            @content;
        }
    }
    @if $media==mobileWide {
        @media only screen and (max-width: $screen-mobileWide) {
            @content;
        }
    }
    @if $media==mobileMedium {
        @media only screen and (max-width: $screen-mobileMedium) {
            @content;
        }
    }
    @if $media==mobile {
        @media only screen and (max-width: $screen-mobile) {
            @content;
        }
    }
    @if $media==mobileSmall {
        @media only screen and (max-width: $screen-mobileSmall) {
            @content;
        }
    }
}

body,
html {
    padding: 0;
    margin: 0;
    background-color: transparent !important;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style-type: none;
}

* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

a{
    text-decoration: none;
}

.buttonPrimary{
    display: inline-block;
    background-color: #331493;
    color: #FFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 30px;
    border-radius: 50px;
    text-decoration: none;
    &:hover{
        color: #FFF;
    }
    @include respond(mobileWide){
        padding: 10px 30px;
        font-size: 14px;
        line-height: 20px;
    } 
}

.buttonSecondary{
    display: inline-block;
    background-color: #FFFFFF;
    color: #1F0D56;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 30px;
    border-radius: 50px;
    text-decoration: none;
    &:hover{
        color: #1F0D56;
    }
    @include respond(tablet){
        padding: 13px 20px;
        font-size: 14px;
        line-height: 20px;
    }
    img{
        width: 23px;
        @include respond(tablet){
            width: 20px;
        }
    }
}

.menuOverlay{
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    -webkit-transition: all .34s ease;
    -moz-transition: all .34s ease;
    -ms-transition: all .34s ease;
    -o-transition: all .34s ease;
    transition: all .34s ease;
    background: #331493;
    visibility: hidden;
    &.active{
        opacity: 0.6;
        visibility: visible;
    }
}

nav{
    padding: 24px 0;
    .headerScope{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .navbar-brand{
            img{
                @include respond(tablet){
                    width: 90px;
                } 
            }
        }
        > ul{
            display: flex;
            margin: 0;
            @include respond(tablet){
                display: none;
            }
            li{
                list-style: none;
                margin-right: 60px;
                &:last-child{
                    margin-right: 0;
                }
                .nav-item-link{
                    padding-top: 5px;
                    padding-bottom: 5px;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #331493;
                    text-decoration: none;
                    white-space: nowrap;
                }
            }
        }
        .mobileHamburgerMenu {
            position: relative;
            width: 22px;
            margin-left: 0;
            -webkit-transition: all .34s ease;
            -moz-transition: all .34s ease;
            -ms-transition: all .34s ease;
            -o-transition: all .34s ease;
            transition: all .34s ease;
            display: none;
            @include respond(tablet){
                display: block;
            }
            &.active{
                .navLine1 {
                    transform: translateY(8px) rotate(45deg);
                }
                .navLine2 {
                    width: 0 !important;
                    right: 50% !important;
                }
                .navLine3 {
                    transform: translateY(-8px) rotate(-45deg);
                }
            }
            .navLineScope {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 22px;
                height: 18px;
                right: 0;
                cursor: pointer;
                .navLine1 {
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    background: #03053D;
                    -webkit-transition: all .34s ease;
                    -moz-transition: all .34s ease;
                    -ms-transition: all .34s ease;
                    -o-transition: all .34s ease;
                    transition: all .34s ease;
                }
                .navLine2 {
                    position: absolute;
                    width: 75%;
                    height: 3px;
                    right: 0;
                    background: #03053D;
                    margin-top: 8px;
                    -webkit-transition: all .34s ease;
                    -moz-transition: all .34s ease;
                    -ms-transition: all .34s ease;
                    -o-transition: all .34s ease;
                    transition: all .34s ease;
                }
                .navLine3 {
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    background: #03053D;
                    margin-top: 16px;
                    -webkit-transition: all .34s ease;
                    -moz-transition: all .34s ease;
                    -ms-transition: all .34s ease;
                    -o-transition: all .34s ease;
                    transition: all .34s ease;
                }
            }
        }
        .navigation-menu{
            position: absolute;
            width: 90%;
            height: 100%;
            background-color: #FFF;
            top: 51%;
            transform: translateY(-50%);
            -webkit-transition: all .34s ease;
            -moz-transition: all .34s ease;
            -ms-transition: all .34s ease;
            -o-transition: all .34s ease;
            transition: all .34s ease;
            display: none;
            &.expanded{
                display: flex;
            }
            ul{
                display: flex;
                padding: 0;
                margin: 0;
                align-items: center;
                width: 100%;
                justify-content: space-around;
                li{
                    a{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #331493;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    
}

.bannerScope{
    .bannerBlock{
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        height: 720px;
        @include respond(desktop){
            height: 500px; 
        }
        @include respond(tablet){
            height: 430px; 
        }
        @include respond(mobileWide){
            height: 335px; 
        }
        .bannerImgEffect{
            position: relative;
            width: 100%;
            height: 100%;
            @include respond(tablet){
                display: none;
            }
            img{
                -webkit-animation: fa-spin-reverse 15s infinite linear;
                animation: fa-spin-reverse 15s infinite linear;
                &.img-1{
                    position: absolute;
                    left: 20%;
                    bottom: 30%;
                }
                &.img-2{
                    position: absolute;
                    left: 26%;
                    top: 20%;
                }
                &.img-3{
                    position: absolute;
                    left: 42%;
                    top: 5%;
                }
                &.img-4{
                    position: absolute;
                    right: 28%;
                    top: 15%;
                }
                &.img-5{
                    position: absolute;
                    right: 20%;
                    bottom: 8%;
                }
            }
              @-webkit-keyframes fa-spin-reverse {
                100% {
                  -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
                }
                0% {
                  -webkit-transform: rotate(359deg);
                  transform: rotate(359deg);
                }
              }
              @keyframes fa-spin-reverse {
                100% {
                  -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
                }
                0% {
                  -webkit-transform: rotate(359deg);
                  transform: rotate(359deg);
                }
              }
        }
        h1{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            font-weight: 700;
            font-size: 73px;
            line-height: 95px;
            text-align: center;
            background: linear-gradient(247.61deg, #A8BBFF 23.95%, #FFFFFF 64.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @include respond(desktop){
                font-size: 50px;
                line-height: 70px;
            }
            @include respond(tablet){
                font-size: 40px;
                line-height: 60px;
            }
            @include respond(mobileWide){
                font-size: 35px;
                line-height: 52px;
            }
        }
        .scrollDownIcon{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 44px;
            bottom: 50px;
            cursor: pointer;
            @include respond(desktop){
                width: 40px;
                bottom: 40px;
            }
            @include respond(tablet){
                width: 40px;
                bottom: 40px;
            }
            @include respond(mobileWide){
                bottom: 18px;
                width: 27px;
            }
        }
    }
}

.optionsCardMain{
    .optionsCardScope{
        background-color: #F5F5F5;
        margin-bottom: 50px;
        &:last-child{
            margin-bottom: 0;
        }
        .optionsCardBlock{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 80px 165px;
            @include respond(desktop){
                padding: 60px 50px;
            }
            @include respond(tablet){
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
                padding: 60px 20px;
            }
            .optionsCardLeftBlock{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-right: 200px;
                @include respond(tablet){
                    align-items: center;
                    margin-right: 0;
                }
                h2{
                    font-weight: 600;
                    font-size: 42px;
                    line-height: 63px;
                    color: #1F0D56;
                    margin-bottom: 25px;
                    @include respond(tablet){
                        font-size: 32px;
                        margin-top: 35px;
                        margin-bottom: 15px;
                        line-height: 50px;
                    }
                }
                p{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin-bottom: 40px;
                    @include respond(tablet){
                        text-align: center;
                        font-size: 14px;
                    }
                }
                .optionsCardDownloadLink{
                    display: flex;
                    a{
                        margin-right: 20px;
                        @include respond(tablet){
                            margin-right: 15px;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
            .optionsCardRightBlock{
                img{
                    width: 230px;
                    height: auto;
                    @include respond(tablet){
                        width: 180px;
                    }
                    @include respond(mobileWide){
                        width: 110px;
                    }
                }
            }
        }
    }
}

.careersCardScope{
    .careersCardBlock{
        display: flex;
        justify-content: center;
        padding: 80px 135px;
        @include respond(desktop){
            padding: 60px 50px;
        }
        @include respond(tablet){
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 60px 20px;
        }
        .careersCardLeftBlock{
            img{
                width: 432px;
                height: auto;
                @include respond(desktop){
                    width: 320px;
                }
                @include respond(tablet){
                    width: 285px;
                }
            }
        }
        .careersCardRightBlock{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 210px;
            @include respond(desktop){
                margin-left: 150px;
            }
            @include respond(tablet){
                padding: 0;
                align-items: center;
                margin-left: 0;
            }
            h2{
                font-weight: 600;
                font-size: 42px;
                line-height: 63px;
                color: #1F0D56;
                margin-bottom: 25px;
                @include respond(tablet){
                    font-size: 40px;
                    margin-top: 35px;
                    margin-bottom: 15px;
                }
            }
            p{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                margin-bottom: 40px;
                @include respond(tablet){
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
    }
}

.textCardScope{
    .textCardBlock{
        padding: 80px 135px;
        @include respond(desktop){
            padding: 60px 50px;
        }
        @include respond(tablet){
            padding: 60px 20px;
        }
        
        h2{
            font-weight: 500;
            font-size: 50px;
            line-height: 75px;
            text-align: center;
            color: #1F0D56;
            margin-bottom: 25px;
            @include respond(tablet){
                font-size: 40px;
            }
        }
        p{
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: #1F0D56;
            @include respond(tablet){
                font-size: 14px;
            }
            strong{
                font-weight: 600;
            }
        }
    }
}

.footerScope{
    background-color: #331493;
    padding: 25px 0;
    .footerTopBlock{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 135px;
        @include respond(desktop){
            padding: 0 50px;
        }
        @include respond(tablet){
            flex-direction: column;
            padding: 0;
        }
        .footerMiddleBlock{
            display: flex;
            ul{
                display: flex;
                margin: 0;
                padding: 0;
                li{
                    margin-right: 40px;
                    padding-top: 15px;
                    @include respond(tablet){
                        padding-top: 40px;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #FFFFFF;
                        text-decoration: none;
                    }
                }
            }
        }
        .footerRightBlock{
            @include respond(tablet){
                padding-top: 40px;
            }
            a{
                margin-right: 40px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .footerMidBlock{
        padding: 0 135px;
        @include respond(desktop){
            padding: 0 50px;
        }
        @include respond(tablet){
            flex-direction: column;
            padding: 0;
        }
        a{
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF;
            text-decoration: none;
            padding-top: 8px;
            @include respond(tablet){
                padding-top: 40px;
                text-align: center;
            }
        }
    }
    .footerBottomBlock{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #FFFFFF;
        text-align: center;
        padding-top: 30px;
        padding-right: 70px;
        @include respond(tablet){
            padding-right: 0;
            padding-top: 40px;
        }
    }
}

.openPositionsScope{
    background-color: #F5F5F5;
    min-height: calc(100vh - 200px);
    .openPositionsBlock{
        display: flex;
        flex-direction: column;
        padding: 60px 0 100px 0;
        @include respond(tablet){
            padding: 0 0 100px 0;
        }
        h2{
            font-weight: 600;
            font-size: 42px;
            line-height: 63px;
            text-align: center;
            color: #1F0D56; 
            padding: 50px 0 50px 0;
            @include respond(tablet){
                font-size: 32px;
                line-height: 48px;
            }
        }
        .openPositionItemScope{
            display: flex;
            flex-wrap: wrap;
            .openPositionItem{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: #FFFFFF;
                padding: 60px 20px;
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
                border-radius: 45px;
                margin-right: 2%;
                margin-bottom: 30px;
                width: 32%;
                &:nth-child(3n){
                    margin-right: 0;
                }
                @include respond(tablet){
                    width: 49%;
                    margin-right: 2%;
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                    &:nth-child(2n+1){
                        margin-right: 2%;
                    }
                }
                @include respond(mobileWide){
                    width: 100%;
                    margin-right: 0%;
                    padding: 40px 65px;
                    border-radius: 32px;
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                    &:nth-child(2n+1){
                        margin-right: 0;
                    }
                }
                h3{
                    font-weight: 600;
                    font-size: 42px;
                    line-height: 63px;
                    text-align: center;
                    color: #1F0D56;
                    margin-bottom: 50px;
                    @include respond(desktop){
                        font-size: 32px;
                        line-height: 54px;
                    }
                    @include respond(tablet){
                        font-size: 28px;
                        line-height: 50px;
                    }
                    @include respond(mobileWide){
                        font-size: 26px;
                        line-height: 42px;
                    }
                }
                a{
                    width: max-content;
                }
            }
        }
    }
}

.positionDetailScope{
    background-color: #F5F5F5;
    .positionDetailBlock{
        display: flex;
        padding: 80px 0;
        min-height: calc(100vh - 175px); 
        @include respond(tablet){
            flex-direction: column;
            padding: 40px 0;

        }
        .positionDetailBackBlock{
            padding-top: 20px;
            padding-right: 100px;
            @include respond(tablet){
                padding-bottom: 25px;
            }
            @include respond(mobileWide){
                padding-left: 14px;
            }
            a{
                display: flex;
                align-items: center;
                text-decoration: none;
                font-weight: 400;
                font-size: 17px;
                line-height: 25px;
                color: #A8A8A8;
                img{
                    margin-right: 15px;
                }
                .backIcon{
                    display: flex;
                    align-items: center; 
                }
            }
        }
        .positionDetailContentBlock{
            padding-right: 150px;
            @include respond(tablet){
                padding-right: 50px;
            }
            @include respond(mobileWide){
                padding-left: 14px;
            }
            h1{
                font-weight: 600;
                font-size: 42px;
                line-height: 63px;
                color: #1F0D56;
                padding-bottom: 50px;
                @include respond(tablet){
                    font-size: 32px;
                    line-height: 48px;
                    padding-bottom: 40px;
                }
            }
            h2{
                font-weight: 600;
                font-size: 25px;
                line-height: 38px;
                color: #1F0D56;
                padding-bottom: 15px;
            }
            h3{
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #1F0D56;
                padding-bottom: 10px;
            }
            p{
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                color: #1F0D56;
                padding-bottom: 25px;
            }
            ul{
                li{
                    list-style: disc;
                }
            }
            a{
                margin-top: 20px;
            }
        }
        
    }
}