@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
/*MEDIA SCREEN VARIABLES*/
/*media screen*/
/*END MEDIA SCREEN VARIABLES*/
/*MIXIN*/
body,
html {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style-type: none;
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.buttonPrimary {
  display: inline-block;
  background-color: #331493;
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 15px 30px;
  border-radius: 50px;
  text-decoration: none;
}
.buttonPrimary:hover {
  color: #FFF;
}
@media only screen and (max-width: 768px) {
  .buttonPrimary {
    padding: 10px 30px;
    font-size: 14px;
    line-height: 20px;
  }
}

.buttonSecondary {
  display: inline-block;
  background-color: #FFFFFF;
  color: #1F0D56;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 15px 30px;
  border-radius: 50px;
  text-decoration: none;
}
.buttonSecondary:hover {
  color: #1F0D56;
}
@media only screen and (max-width: 1080px) {
  .buttonSecondary {
    padding: 13px 20px;
    font-size: 14px;
    line-height: 20px;
  }
}
.buttonSecondary img {
  width: 23px;
}
@media only screen and (max-width: 1080px) {
  .buttonSecondary img {
    width: 20px;
  }
}

.menuOverlay {
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  transition: all 0.34s ease;
  background: #331493;
  visibility: hidden;
}
.menuOverlay.active {
  opacity: 0.6;
  visibility: visible;
}

nav {
  padding: 24px 0;
}
nav .headerScope {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1080px) {
  nav .headerScope .navbar-brand img {
    width: 90px;
  }
}
nav .headerScope > ul {
  display: flex;
  margin: 0;
}
@media only screen and (max-width: 1080px) {
  nav .headerScope > ul {
    display: none;
  }
}
nav .headerScope > ul li {
  list-style: none;
  margin-right: 60px;
}
nav .headerScope > ul li:last-child {
  margin-right: 0;
}
nav .headerScope > ul li .nav-item-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #331493;
  text-decoration: none;
  white-space: nowrap;
}
nav .headerScope .mobileHamburgerMenu {
  position: relative;
  width: 22px;
  margin-left: 0;
  transition: all 0.34s ease;
  display: none;
}
@media only screen and (max-width: 1080px) {
  nav .headerScope .mobileHamburgerMenu {
    display: block;
  }
}
nav .headerScope .mobileHamburgerMenu.active .navLine1 {
  transform: translateY(8px) rotate(45deg);
}
nav .headerScope .mobileHamburgerMenu.active .navLine2 {
  width: 0 !important;
  right: 50% !important;
}
nav .headerScope .mobileHamburgerMenu.active .navLine3 {
  transform: translateY(-8px) rotate(-45deg);
}
nav .headerScope .mobileHamburgerMenu .navLineScope {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 18px;
  right: 0;
  cursor: pointer;
}
nav .headerScope .mobileHamburgerMenu .navLineScope .navLine1 {
  position: absolute;
  width: 100%;
  height: 3px;
  background: #03053D;
  transition: all 0.34s ease;
}
nav .headerScope .mobileHamburgerMenu .navLineScope .navLine2 {
  position: absolute;
  width: 75%;
  height: 3px;
  right: 0;
  background: #03053D;
  margin-top: 8px;
  transition: all 0.34s ease;
}
nav .headerScope .mobileHamburgerMenu .navLineScope .navLine3 {
  position: absolute;
  width: 100%;
  height: 3px;
  background: #03053D;
  margin-top: 16px;
  transition: all 0.34s ease;
}
nav .headerScope .navigation-menu {
  position: absolute;
  width: 90%;
  height: 100%;
  background-color: #FFF;
  top: 51%;
  transform: translateY(-50%);
  transition: all 0.34s ease;
  display: none;
}
nav .headerScope .navigation-menu.expanded {
  display: flex;
}
nav .headerScope .navigation-menu ul {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
nav .headerScope .navigation-menu ul li a {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #331493;
  text-decoration: none;
}

.bannerScope .bannerBlock {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 720px;
}
@media only screen and (max-width: 1440px) {
  .bannerScope .bannerBlock {
    height: 500px;
  }
}
@media only screen and (max-width: 1080px) {
  .bannerScope .bannerBlock {
    height: 430px;
  }
}
@media only screen and (max-width: 768px) {
  .bannerScope .bannerBlock {
    height: 335px;
  }
}
.bannerScope .bannerBlock .bannerImgEffect {
  position: relative;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1080px) {
  .bannerScope .bannerBlock .bannerImgEffect {
    display: none;
  }
}
.bannerScope .bannerBlock .bannerImgEffect img {
  -webkit-animation: fa-spin-reverse 15s infinite linear;
  animation: fa-spin-reverse 15s infinite linear;
}
.bannerScope .bannerBlock .bannerImgEffect img.img-1 {
  position: absolute;
  left: 20%;
  bottom: 30%;
}
.bannerScope .bannerBlock .bannerImgEffect img.img-2 {
  position: absolute;
  left: 26%;
  top: 20%;
}
.bannerScope .bannerBlock .bannerImgEffect img.img-3 {
  position: absolute;
  left: 42%;
  top: 5%;
}
.bannerScope .bannerBlock .bannerImgEffect img.img-4 {
  position: absolute;
  right: 28%;
  top: 15%;
}
.bannerScope .bannerBlock .bannerImgEffect img.img-5 {
  position: absolute;
  right: 20%;
  bottom: 8%;
}
@-webkit-keyframes fa-spin-reverse {
  100% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(359deg);
  }
}
@keyframes fa-spin-reverse {
  100% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(359deg);
  }
}
.bannerScope .bannerBlock h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-weight: 700;
  font-size: 73px;
  line-height: 95px;
  text-align: center;
  background: linear-gradient(247.61deg, #A8BBFF 23.95%, #FFFFFF 64.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (max-width: 1440px) {
  .bannerScope .bannerBlock h1 {
    font-size: 50px;
    line-height: 70px;
  }
}
@media only screen and (max-width: 1080px) {
  .bannerScope .bannerBlock h1 {
    font-size: 40px;
    line-height: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .bannerScope .bannerBlock h1 {
    font-size: 35px;
    line-height: 52px;
  }
}
.bannerScope .bannerBlock .scrollDownIcon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 44px;
  bottom: 50px;
  cursor: pointer;
}
@media only screen and (max-width: 1440px) {
  .bannerScope .bannerBlock .scrollDownIcon {
    width: 40px;
    bottom: 40px;
  }
}
@media only screen and (max-width: 1080px) {
  .bannerScope .bannerBlock .scrollDownIcon {
    width: 40px;
    bottom: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .bannerScope .bannerBlock .scrollDownIcon {
    bottom: 18px;
    width: 27px;
  }
}

.optionsCardMain .optionsCardScope {
  background-color: #F5F5F5;
  margin-bottom: 50px;
}
.optionsCardMain .optionsCardScope:last-child {
  margin-bottom: 0;
}
.optionsCardMain .optionsCardScope .optionsCardBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 165px;
}
@media only screen and (max-width: 1440px) {
  .optionsCardMain .optionsCardScope .optionsCardBlock {
    padding: 60px 50px;
  }
}
@media only screen and (max-width: 1080px) {
  .optionsCardMain .optionsCardScope .optionsCardBlock {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
  }
}
.optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 200px;
}
@media only screen and (max-width: 1080px) {
  .optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock {
    align-items: center;
    margin-right: 0;
  }
}
.optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock h2 {
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  color: #1F0D56;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1080px) {
  .optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock h2 {
    font-size: 32px;
    margin-top: 35px;
    margin-bottom: 15px;
    line-height: 50px;
  }
}
.optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1080px) {
  .optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock p {
    text-align: center;
    font-size: 14px;
  }
}
.optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock .optionsCardDownloadLink {
  display: flex;
}
.optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock .optionsCardDownloadLink a {
  margin-right: 20px;
}
@media only screen and (max-width: 1080px) {
  .optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock .optionsCardDownloadLink a {
    margin-right: 15px;
  }
}
.optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardLeftBlock .optionsCardDownloadLink a:last-child {
  margin-right: 0;
}
.optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardRightBlock img {
  width: 230px;
  height: auto;
}
@media only screen and (max-width: 1080px) {
  .optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardRightBlock img {
    width: 180px;
  }
}
@media only screen and (max-width: 768px) {
  .optionsCardMain .optionsCardScope .optionsCardBlock .optionsCardRightBlock img {
    width: 110px;
  }
}

.careersCardScope .careersCardBlock {
  display: flex;
  justify-content: center;
  padding: 80px 135px;
}
@media only screen and (max-width: 1440px) {
  .careersCardScope .careersCardBlock {
    padding: 60px 50px;
  }
}
@media only screen and (max-width: 1080px) {
  .careersCardScope .careersCardBlock {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
  }
}
.careersCardScope .careersCardBlock .careersCardLeftBlock img {
  width: 432px;
  height: auto;
}
@media only screen and (max-width: 1440px) {
  .careersCardScope .careersCardBlock .careersCardLeftBlock img {
    width: 320px;
  }
}
@media only screen and (max-width: 1080px) {
  .careersCardScope .careersCardBlock .careersCardLeftBlock img {
    width: 285px;
  }
}
.careersCardScope .careersCardBlock .careersCardRightBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 210px;
}
@media only screen and (max-width: 1440px) {
  .careersCardScope .careersCardBlock .careersCardRightBlock {
    margin-left: 150px;
  }
}
@media only screen and (max-width: 1080px) {
  .careersCardScope .careersCardBlock .careersCardRightBlock {
    padding: 0;
    align-items: center;
    margin-left: 0;
  }
}
.careersCardScope .careersCardBlock .careersCardRightBlock h2 {
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  color: #1F0D56;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1080px) {
  .careersCardScope .careersCardBlock .careersCardRightBlock h2 {
    font-size: 40px;
    margin-top: 35px;
    margin-bottom: 15px;
  }
}
.careersCardScope .careersCardBlock .careersCardRightBlock p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1080px) {
  .careersCardScope .careersCardBlock .careersCardRightBlock p {
    text-align: center;
    font-size: 14px;
  }
}

.textCardScope .textCardBlock {
  padding: 80px 135px;
}
@media only screen and (max-width: 1440px) {
  .textCardScope .textCardBlock {
    padding: 60px 50px;
  }
}
@media only screen and (max-width: 1080px) {
  .textCardScope .textCardBlock {
    padding: 60px 20px;
  }
}
.textCardScope .textCardBlock h2 {
  font-weight: 500;
  font-size: 50px;
  line-height: 75px;
  text-align: center;
  color: #1F0D56;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1080px) {
  .textCardScope .textCardBlock h2 {
    font-size: 40px;
  }
}
.textCardScope .textCardBlock p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #1F0D56;
}
@media only screen and (max-width: 1080px) {
  .textCardScope .textCardBlock p {
    font-size: 14px;
  }
}
.textCardScope .textCardBlock p strong {
  font-weight: 600;
}

.footerScope {
  background-color: #331493;
  padding: 25px 0;
}
.footerScope .footerTopBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 135px;
}
@media only screen and (max-width: 1440px) {
  .footerScope .footerTopBlock {
    padding: 0 50px;
  }
}
@media only screen and (max-width: 1080px) {
  .footerScope .footerTopBlock {
    flex-direction: column;
    padding: 0;
  }
}
.footerScope .footerTopBlock .footerMiddleBlock {
  display: flex;
}
.footerScope .footerTopBlock .footerMiddleBlock ul {
  display: flex;
  margin: 0;
  padding: 0;
}
.footerScope .footerTopBlock .footerMiddleBlock ul li {
  margin-right: 40px;
  padding-top: 15px;
}
@media only screen and (max-width: 1080px) {
  .footerScope .footerTopBlock .footerMiddleBlock ul li {
    padding-top: 40px;
  }
}
.footerScope .footerTopBlock .footerMiddleBlock ul li:last-child {
  margin-right: 0;
}
.footerScope .footerTopBlock .footerMiddleBlock ul li a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  text-decoration: none;
}
@media only screen and (max-width: 1080px) {
  .footerScope .footerTopBlock .footerRightBlock {
    padding-top: 40px;
  }
}
.footerScope .footerTopBlock .footerRightBlock a {
  margin-right: 40px;
}
.footerScope .footerTopBlock .footerRightBlock a:last-child {
  margin-right: 0;
}
.footerScope .footerMidBlock {
  padding: 0 135px;
}
@media only screen and (max-width: 1440px) {
  .footerScope .footerMidBlock {
    padding: 0 50px;
  }
}
@media only screen and (max-width: 1080px) {
  .footerScope .footerMidBlock {
    flex-direction: column;
    padding: 0;
  }
}
.footerScope .footerMidBlock a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  text-decoration: none;
  padding-top: 8px;
}
@media only screen and (max-width: 1080px) {
  .footerScope .footerMidBlock a {
    padding-top: 40px;
    text-align: center;
  }
}
.footerScope .footerBottomBlock {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
  text-align: center;
  padding-top: 30px;
  padding-right: 70px;
}
@media only screen and (max-width: 1080px) {
  .footerScope .footerBottomBlock {
    padding-right: 0;
    padding-top: 40px;
  }
}

.openPositionsScope {
  background-color: #F5F5F5;
  min-height: calc(100vh - 200px);
}
.openPositionsScope .openPositionsBlock {
  display: flex;
  flex-direction: column;
  padding: 60px 0 100px 0;
}
@media only screen and (max-width: 1080px) {
  .openPositionsScope .openPositionsBlock {
    padding: 0 0 100px 0;
  }
}
.openPositionsScope .openPositionsBlock h2 {
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  text-align: center;
  color: #1F0D56;
  padding: 50px 0 50px 0;
}
@media only screen and (max-width: 1080px) {
  .openPositionsScope .openPositionsBlock h2 {
    font-size: 32px;
    line-height: 48px;
  }
}
.openPositionsScope .openPositionsBlock .openPositionItemScope {
  display: flex;
  flex-wrap: wrap;
}
.openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  padding: 60px 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 45px;
  margin-right: 2%;
  margin-bottom: 30px;
  width: 32%;
}
.openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem:nth-child(3n) {
  margin-right: 0;
}
@media only screen and (max-width: 1080px) {
  .openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem {
    width: 49%;
    margin-right: 2%;
  }
  .openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem:nth-child(2n) {
    margin-right: 0;
  }
  .openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem:nth-child(2n+1) {
    margin-right: 2%;
  }
}
@media only screen and (max-width: 768px) {
  .openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem {
    width: 100%;
    margin-right: 0%;
    padding: 40px 65px;
    border-radius: 32px;
  }
  .openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem:nth-child(2n) {
    margin-right: 0;
  }
  .openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem:nth-child(2n+1) {
    margin-right: 0;
  }
}
.openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem h3 {
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  text-align: center;
  color: #1F0D56;
  margin-bottom: 50px;
}
@media only screen and (max-width: 1440px) {
  .openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem h3 {
    font-size: 32px;
    line-height: 54px;
  }
}
@media only screen and (max-width: 1080px) {
  .openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem h3 {
    font-size: 28px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem h3 {
    font-size: 26px;
    line-height: 42px;
  }
}
.openPositionsScope .openPositionsBlock .openPositionItemScope .openPositionItem a {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.positionDetailScope {
  background-color: #F5F5F5;
}
.positionDetailScope .positionDetailBlock {
  display: flex;
  padding: 80px 0;
  min-height: calc(100vh - 175px);
}
@media only screen and (max-width: 1080px) {
  .positionDetailScope .positionDetailBlock {
    flex-direction: column;
    padding: 40px 0;
  }
}
.positionDetailScope .positionDetailBlock .positionDetailBackBlock {
  padding-top: 20px;
  padding-right: 100px;
}
@media only screen and (max-width: 1080px) {
  .positionDetailScope .positionDetailBlock .positionDetailBackBlock {
    padding-bottom: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .positionDetailScope .positionDetailBlock .positionDetailBackBlock {
    padding-left: 14px;
  }
}
.positionDetailScope .positionDetailBlock .positionDetailBackBlock a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #A8A8A8;
}
.positionDetailScope .positionDetailBlock .positionDetailBackBlock a img {
  margin-right: 15px;
}
.positionDetailScope .positionDetailBlock .positionDetailBackBlock a .backIcon {
  display: flex;
  align-items: center;
}
.positionDetailScope .positionDetailBlock .positionDetailContentBlock {
  padding-right: 150px;
}
@media only screen and (max-width: 1080px) {
  .positionDetailScope .positionDetailBlock .positionDetailContentBlock {
    padding-right: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .positionDetailScope .positionDetailBlock .positionDetailContentBlock {
    padding-left: 14px;
  }
}
.positionDetailScope .positionDetailBlock .positionDetailContentBlock h1 {
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  color: #1F0D56;
  padding-bottom: 50px;
}
@media only screen and (max-width: 1080px) {
  .positionDetailScope .positionDetailBlock .positionDetailContentBlock h1 {
    font-size: 32px;
    line-height: 48px;
    padding-bottom: 40px;
  }
}
.positionDetailScope .positionDetailBlock .positionDetailContentBlock h2 {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #1F0D56;
  padding-bottom: 15px;
}
.positionDetailScope .positionDetailBlock .positionDetailContentBlock h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #1F0D56;
  padding-bottom: 10px;
}
.positionDetailScope .positionDetailBlock .positionDetailContentBlock p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #1F0D56;
  padding-bottom: 25px;
}
.positionDetailScope .positionDetailBlock .positionDetailContentBlock ul li {
  list-style: disc;
}
.positionDetailScope .positionDetailBlock .positionDetailContentBlock a {
  margin-top: 20px;
}/*# sourceMappingURL=global.css.map */